import { useState, useEffect, useRef } from 'react';
import { client as httpClient } from '../http';

const MAINTENANCE_INTERVAL = 1000 * 60 * 5; // 5 minutes
const { MAINTENANCE_MODE_URI } = process.env;

const useMaintenanceMode = () => {
  const timerId = useRef(null);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useEffect(() => {
    if (!MAINTENANCE_MODE_URI) {
      return;
    }
    const checkMaintenanceMode = async () => {
      try {
        await httpClient
          .get({
            url: MAINTENANCE_MODE_URI,
            useJwt: false,
            checkStatus: true,
            isJson: false,
            logError: false,
          })
          .then(() => {
            // If the request is successful (20X), maintenance mode is on
            if (!isMaintenanceMode) setIsMaintenanceMode(true);
          })
          .catch(() => {
            // If the request fails, assume maintenance mode is off
            if (isMaintenanceMode) setIsMaintenanceMode(false);
          });
      } catch (err) {
        if (isMaintenanceMode) setIsMaintenanceMode(false);
        console.error('Maintenance Mode error: ', err);
      }
    };

    // Check on init
    checkMaintenanceMode();

    timerId.current = setInterval(checkMaintenanceMode, MAINTENANCE_INTERVAL);

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, []);

  return isMaintenanceMode;
};

export default useMaintenanceMode;
