import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { OfflineNotice } from '../components';

const BaseContainer = styled.div`
  display: grid;
  min-height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  grid-gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
`;

const BaseContainerAlt = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
`;

const HeaderContainer = styled.header`
  grid-area: header;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const ContentContainer = styled.main`
  grid-area: main;
  overflow: auto;
  padding: 0 0 1rem 0;
  margin: 0;
  margin-top: 4rem;
  display: flex;
  flex: 1;
  width: 100%;
`;

const ContentContainerAlt = styled.main`
  grid-area: main;
  overflow: auto;
  padding: 0;
  margin: 0;
  margin-top: 0;
  display: flex;
  flex: 1;
  width: 100%;
`;

const FooterContainer = styled.footer`
  grid-area: footer;
`;

const BaseLayout = ({
  header, footer, children, isAuthenticated,
}) => {
  const BaseContainerEl = useMemo(() => {
    return (header || footer)
      ? BaseContainer
      : BaseContainerAlt;
  }, [header, footer]);

  const ContentContainerEl = useMemo(() => {
    return (header || footer)
      ? ContentContainer
      : ContentContainerAlt;
  }, [header, footer]);

  const renderMainContent = () => (
    <ContentContainerEl>
      {children || null}
    </ContentContainerEl>
  );

  return (
    <>
      <Helmet>
        <title>{`${process.env.APP_NAME}`}</title>
      </Helmet>
      <BaseContainerEl>
        {header && <HeaderContainer>{header}</HeaderContainer>}
        <OfflineNotice />
        {renderMainContent()}
        {footer && <FooterContainer>{footer}</FooterContainer>}
      </BaseContainerEl>
    </>
  );
};

export default BaseLayout;
