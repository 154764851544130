import React, { useReducer, useContext, createContext } from 'react';

const TemplateContext = createContext({});

const INITIAL_STATE = {};

const reducer = (state, action) => ({ ...state, ...action.payload });

export const TemplateContextProvider = ({ children }) => {
  const [templateState, dispatch] = useReducer(reducer, INITIAL_STATE);

  const handleSetTemplateState = (payload) => {
    dispatch({ type: 'update', payload });
  };

  return (
    <TemplateContext.Provider value={[templateState, handleSetTemplateState]}>
      {children}
    </TemplateContext.Provider>
  );
};

const useTemplateContext = () => {
  const state = useContext(TemplateContext);
  return state;
};

export default useTemplateContext;
