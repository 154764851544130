import styled from 'styled-components';
import useViewport from '../../hooks/useViewport';
import { Error, Button, Link } from '../../common';
import { PageContainer } from '../../components';

const Container = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 33.25rem;
  padding: 0;
  margin: 0;
`;

const MobileContentWrapper = styled.div`
  width: 90%;
`;

const MaintenanceMode = () => {
  const { isMobile } = useViewport();

  const handleNavigate = () => {
    const { HELP_URI } = process.env;
    const win = window.open(HELP_URI, '_blank');
    win.focus();
  };

  const renderMessage = () => {
    return (
      <p>We're currently tuning up our services to enhance your experience. If you need assistance or have any questions, please visit our <Link onClick={handleNavigate}>Help Center</Link>. We'll be back online shortly. Thank you for your patience!</p>
    );
  };

  const renderContent = () => {
    const ContentWrapperEl = isMobile ? MobileContentWrapper : ContentWrapper;
    return (
      <ContentWrapperEl>
        <Error header="Maintenance in Progress" content={renderMessage()} type="warning" />
        <Button
          sx={{ marginTop: '1rem' }}
          onClick={() => handleNavigate()}
          fullWidth={true}
          variant="outlined"
        >
          Visit Help Center
        </Button>
      </ContentWrapperEl>
    );
  };

  return (
    <PageContainer
      pageTitle='Maintenance'
      loading={false}
      showPageTitle={false}
      container={Container}
    >
      {renderContent()}
    </PageContainer>
  );
};

export default MaintenanceMode;
