import React from 'react';
import styledNormalize from 'emotion-normalize';
import { Global, css } from '@emotion/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { COLORS } from './Consts';

const gStyles = css`
  ${styledNormalize}

  ${
    '' /* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500&display=swap'); */
  }
  @supports not (font-variation-settings: normal) {
    @font-face {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 300;
      font-display: swap;
      src: url(/public/fonts/Outfit-Regular.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(/public/fonts/Outfit-Medium.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url(/public/fonts/Outfit-Bold.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 600;
      font-display: swap;
      src: url(/public/fonts/Outfit-ExtraBold.ttf) format('truetype');
    }
    @font-face {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: url(/public/fonts/Outfit-Black.ttf) format('truetype');
    }
  }
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(/public/fonts/mui-icons.woff2) format('woff2');
  }

  @supports (font-variation-settings: normal) {
    @font-face {
      font-family: 'Outfit';
      src: url('/public/fonts/Outfit-VF.ttf')
          format('truetype supports variations'),
        url('/public/fonts/Outfit-VF.ttf') format('truetype-variations');
      font-weight: 100 1000;
      font-stretch: 25% 151%;
    }
  }

  :root {
    --brand: ${COLORS.brand};
    --primary: ${COLORS.primary};
    --link-color: ${COLORS.primary};
    --link-hover-color: ${COLORS.primary};

    --black: ${COLORS.black};
    --white: ${COLORS.white};
    --charcoal: ${COLORS.charcoal};
    --darkGray: ${COLORS.darkGray};
    --gray: ${COLORS.gray};
    --altGray: ${COLORS.altGray};
    --lightGray: ${COLORS.lightGray};
    --yellow: ${COLORS.yellow};
    --red: ${COLORS.red};
    --green: ${COLORS.green};
    --orange: ${COLORS.orange};

    --doc-height: 100%;
    --max-content-width: vw;
  }

  * {
    font-family: 'Outfit', sans-serif;
    font-weight: 300;
    box-sizing: border-box;
  }

  button,
  input,
  select,
  li,
  a,
  p,
  b,
  span,
  div,
  label,
  time,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  text {
    font-family: 'Outfit', sans-serif;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: var(--link-color);
    :hover {
      color: var(--link-hover-color);
      cursor: pointer;
    }
  }

  b {
    font-weight: 600;
  }

  html {
    min-height: -webkit-fill-available;
    touch-action: manipulation;
    overscroll-behavior-y: contain;
  }

  body {
    padding: 0;
    margin: 0;
    ${'' /* overflow: hidden; */}
    max-height: 100vh;
    max-width: 100vw;
    box-sizing: border-box;
    background-color: var(--white) !important;
    min-height: -webkit-fill-available;
    touch-action: manipulation;
    overscroll-behavior-y: contain;
  }
`;

export const GlobalStyles = (props) => {
  return <Global styles={gStyles} {...props} />;
};

export const themeOptions = {
  typography: {
    fontFamily: ['Outfit', 'sans-serif'].join(','),
  },
  palette: {
    type: 'light',
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.primary,
    },
    background: {
      default: COLORS.white,
      paper: COLORS.white,
    },
    info: {
      main: COLORS.primary,
    },
    success: {
      main: COLORS.primary,
      contrastText: COLORS.white,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          backgroundColor: COLORS.charcoal,
          '&:hover': {
            backgroundColor: COLORS.black,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: COLORS.gray,
        },
        shrink: {
          color: COLORS.charcoal,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          fontWeight: 600,
        },
        contained: {
          fontWeight: 600,
          border: 'none',
          boxShadow: 'none',
          borderRadius: '5px',
          // color: COLORS.primary,
          backgroundColor: COLORS.primary,
          padding: '0.75rem 1rem',
          '&:hover': {
            color: COLORS.white,
            backgroundColor: COLORS.primary,
            boxShadow: 'none',
            opacity: 0.9,
          },
          '&.Mui-disabled': {
            color: COLORS.primary,
            backgroundColor: COLORS.gray,
            cursor: 'not-allowed',
          },
        },
        outlined: {
          fontWeight: 600,
          border: `1px solid ${COLORS.gray}`,
          color: COLORS.charcoal,
          // backgroundColor: COLORS.charcoal,
          padding: '0.75rem 1rem',
          '&:hover': {
            color: COLORS.black,
            border: `1px solid ${COLORS.black}`,
            backgroundColor: COLORS.lightGray,
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            color: COLORS.gray,
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {},
        outlined: {
          loadingIndicator: {
            color: COLORS.charcoal,
          },
        },
        text: {
          loadingIndicator: {
            color: COLORS.charcoal,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {},
        },
        indicator: {
          display: 'none',
        },
        flexContainer: {
          borderBottom: `1px solid ${COLORS.gray}`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          '&.MuiButtonBase-root': {
            '&.Mui-selected': {
              backgroundColor: COLORS.lightGray,
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
            },
            fontSize: '1rem',
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          fontFamily: 'Material Icons',
          fontWeight: 'normal',
          fontStyle: 'normal',
          lineHeight: 1,
          letterSpacing: 'normal',
          textTransform: 'none',
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordWrap: 'normal',
          direction: 'ltr',
          textTendering: 'optimizeLegibility',
          WebkitFontSmoothing: 'antialiased',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 700,
          fontSize: '3.5rem',
        },
        h2: {
          fontWeight: 700,
          fontSize: '3rem',
        },
        h3: {
          fontWeight: 700,
          fontSize: '2.5rem',
        },
        h4: {
          fontWeight: 700,
          fontSize: '2rem',
        },
        h5: {
          fontWeight: 700,
          fontSize: '1.5rem',
        },
        h6: {
          fontWeight: 700,
          fontSize: '1rem',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          label: {
            fontSize: '1rem',
            fontWeight: 400,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {},
        root: {
          '&.Mui-disabled': {
            backgroundColor: COLORS.lightGray,
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
};

let timeoutId = null;
const documentHeight = () => {
  clearTimeout(timeoutId); // avoid execution of previous timeouts
  timeoutId = setTimeout(() => {
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
  }, 200);
};
window.addEventListener('resize', documentHeight);
documentHeight();

const theme = createTheme(themeOptions);

export const GlobalThemeProvider = ({ children, ...props }) =>
  React.createElement(ThemeProvider, { ...props, theme }, children);
